/* 
from
https://codepen.io/davidicus/pen/yyxoXW


/*== start of code for tooltips ==*/
.tool {
    cursor: help;
    position: relative;
}


/*== common styles for both parts of tool tip ==*/
.tool::before,
.tool::after {
    left: 50%;
    opacity: 0;
    position: absolute;
    z-index: -100;
}

.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 100; 
}


/*== pointer tip ==*/
/* .tool::before {
    border-style: solid;
    border-width: 1em 0.75em 0 0.75em;
    border-color: #3E474F transparent transparent transparent;
    bottom: 100%;
    content: "";
    margin-left: -0.5em;
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
    transform:  scale(.6) translateY(-90%);
} 

.tool:hover::before,
.tool:focus::before {
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
} */


/*== speech bubble ==*/
.tool::after {
    background: #3E474F;
    border-radius: .25em;
    bottom: 178%;
    color: #EDEFF0;
    content: attr(data-tip);
    line-height: 1.2;
    margin-left: -15vw;
    margin-bottom: -50vh;
    padding: 1em;
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
    transform:  scale(1) translateY(-80%);  
    /* width: 17.5em; */
    width:30vw;
}

.tool:hover::after,
.tool:focus::after  {
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
}

@media (max-width: 960px) {
    .tool::after { 
          font-size: 1em;
          /* margin-left: -5em; */
          /* width: 10em;  */
          margin-left: -20vw;
          margin-bottom: -48vh;
          width:40vw;
          
    }
  }

@media (max-width: 760px) {
    .tool::after { 
          font-size: 1em;
          /* margin-left: -5em; */
          /* width: 10em;  */
          margin-left: -25vw;
          margin-bottom: -50vh;
          width:50vw;
          
    }
  }

  @media (max-width: 680px) {
    .tool::after { 
          font-size: 1em;
          /* margin-left: -5em; */
          /* width: 10em;  */
          margin-left: -25vw;
          margin-bottom: -53vh;
          width:50vw;
          
    }
  }

  @media (max-width: 500px) {
    .tool::after { 
          font-size: 1em;
          /* margin-left: -5em; */
          /* width: 10em;  */
          margin-left: -30vw;
          margin-bottom: -55vh;
          width:60vw;
          
    }
  }

  @media (max-width: 400px) {
    .tool::after { 
          font-size: 1em;
          /* margin-left: -5em; */
          /* width: 10em;  */
          margin-left: -30vw;
          margin-bottom: -70vh;
          width:60vw;
          
    }
  }

.tooltiplabel{
    font-weight: bold;
    color: #3e7cb1;
}