select:focus, select:active {
    /* outline: -webkit-focus-ring-color auto 1px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 1px; */
    /* outline-width: 4px; */
    outline: #3e7cb1 1px solid;
    border: none;
}

/* select:not(:-internal-list-box) {
    background-color: orange;
    overflow: visible !important;
} */