@import url(https://fonts.googleapis.com/css?family=Audiowide);
 
 .homePageBackgroundDiv{
    background-image: url('/images/Background.png');
    background-position: center center; 
    background-size: cover;
    background-attachment: fixed; 
    background-repeat: no-repeat; 
    width:100vw;
    height:100vh;
}

.HomePageContentDiv{
    padding-top: 15%;
    transition: padding .5s ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.HomePageContentDiv > *{
    padding:5px 15px 5px 15px;
    text-align: center;
}

.passwordDiv , .passwordDiv input{
    display: inline-block;
    text-align: center;
    width: 100%;
    height: 30px;
}

.HomePageContentDiv .title{
color:#3e7cb1;
font-style: bold;
font-family: "Audiowide", cursive;
}

.passwordDiv input{
    background-color: white;
    outline: none;
    border: none;
    border-radius: 0px;
    padding-top: 0;
    padding-bottom: 0;
}

.passwordDiv input.password{
    background-color: white;
    width: 75%;
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.inputDiv button {
    background-color: #3e7cb1;
    color:white;
    width:25%;
    outline: none;
    border: none;
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}


.inputDiv button:hover{
    background-color: #054a91;
}

.btn-primary {
    color: #fff;
    background-color: #3e7cb1;
    border: none;
  }
  
  .btn-primary:hover {
    color: #fff;
    background-color: #054a91;
  }

  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
    color: #fff;
    /* background-color: #054a91; */
    background-color: #3e7cb1;
    border-color: #3e7cb1;
  }

.passwordDiv {
    min-width: 150px;
    max-width: 350px;
}

@media(min-width:850px){
    .HomePageContentDiv{
        padding-top: 10%;
    }

}