
/* .EndResultsBackgroundDiv{
    background-image: url('/images/TopBlueBar.png');
    background-position: 0px 0px; 
    background-size: cover;
    background-attachment: fixed; 
    background-repeat: no-repeat; 
    width:100vw;
    height:100vh;
} */

.CenterAll{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.EndResultsTopBarDiv{
    min-height: 10vh;
    background-color: #054a91;
}

.EndResultsContentDiv{
    padding-top: 8px;
}

.btn-link, .btn-link:hover{
    color: #fff;
    text-decoration: none;
}