.preResultMultiSelectDiv .selected-multiselect{
    border: red solid 2px;
}

.preResultMultiSelectDiv .unselected-multiselect{
    border: yellow solid 2px;
}

.preResultMultiSelectDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 5%;
    transition: padding .3s ;
}

.preResultMultiSelectDiv .preResultsSelectButtons{
    padding: 10px;
}

.preResultMultiSelectDiv .preResultsMultiSelect{
    margin: 7px;
    border-radius: .25rem;
    outline: none;
}

.preResultsSelectButtons .selected-multiselect{
    border: rgb(163, 163, 163) solid 2px;
    color: white;
    background-color: #3e7cb1;
}

.preResultsSelectButtons .unselected-multiselect{
    border: rgb(124, 124, 124) solid 2px;
}