.multiQuestionDiv, .dualityQuestionDiv, .dropdownQuestionDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 28%;
    transition: padding .3s ;
} 

@media(min-width:525px){
    .multiQuestionDiv, .dualityQuestionDiv, .dropdownQuestionDiv{
        padding-top: 20%;
    }
}

@media(min-width:850px){
    .multiQuestionDiv, .dualityQuestionDiv, .dropdownQuestionDiv{
        padding-top: 13%;
    }
}



.selectDiv{
    width:60%;
    max-width: 350px;
}

.selectDiv select {
    padding: 7px;
    margin-top: 15px;
    margin-bottom: 15px;
    width:100%;
    color:#054a91
}

.dropdownQuestionDiv button, .multiQuestionDiv button, .dualityQuestionDiv button{
    width:60%;
    max-width: 350px;
    background-color: #3e7cb1;
    color:white;
    outline: none;
    border: none;
}

.btn-primary.focus, .btn-primary:focus {
    box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}


.multiQuestionDiv button, .dualityQuestionDiv button{
    margin-top: 15px;
}


button.btn-secondary.goBackBtn{
    background-color: #adadad;
    margin-top: 50px;
    max-width: 200px;
}
button.btn-secondary.goBackBtn:hover{
    background-color: #6d6d6d;
}

.btn-secondary.focus, .btn-secondary:focus {
    box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus{
    box-shadow: none;
}