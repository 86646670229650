.AdminNewPage, .AdminEditPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AdminNewPage form, .AdminEditPage form{
    width:70%;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AdminEditPage form .btn.btn-danger{
    margin-top: 30px;
}

.titleDiv, .descriptionDiv, .intructionsDiv, .prereqDiv, .prereq_descriptionDiv, .tagSelectDiv{
    width: 100%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
}

.titleDiv input, .descriptionDiv textarea, .intructionsDiv textarea, .prereqDiv textarea, .prereq_descriptionDiv textarea {
    width: 100%
}

.descriptionDiv textarea, .intructionsDiv textarea, .prereqDiv textarea, .prereq_descriptionDiv textarea{
    height: 40vh;
}

.tagSelectDiv button{
    margin-top: 10px;
}

.tagsToAddList{
    padding-top: 30px;
}

.original_tag{
    background-color: rgb(255, 180, 89);
}

.takequizAdminBtnDiv{
    margin-top: 3vh;
}