@import url(https://fonts.googleapis.com/css?family=Audiowide);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e6e5;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.homePageBackgroundDiv{
    background-image: url('/images/Background.png');
    background-position: center center; 
    background-size: cover;
    background-attachment: fixed; 
    background-repeat: no-repeat; 
    width:100vw;
    height:100vh;
}

.HomePageContentDiv{
    padding-top: 15%;
    transition: padding .5s ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.HomePageContentDiv > *{
    padding:5px 15px 5px 15px;
    text-align: center;
}

.passwordDiv , .passwordDiv input{
    display: inline-block;
    text-align: center;
    width: 100%;
    height: 30px;
}

.HomePageContentDiv .title{
color:#3e7cb1;
font-style: bold;
font-family: "Audiowide", cursive;
}

.passwordDiv input{
    background-color: white;
    outline: none;
    border: none;
    border-radius: 0px;
    padding-top: 0;
    padding-bottom: 0;
}

.passwordDiv input.password{
    background-color: white;
    width: 75%;
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.inputDiv button {
    background-color: #3e7cb1;
    color:white;
    width:25%;
    outline: none;
    border: none;
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}


.inputDiv button:hover{
    background-color: #054a91;
}

.btn-primary {
    color: #fff;
    background-color: #3e7cb1;
    border: none;
  }
  
  .btn-primary:hover {
    color: #fff;
    background-color: #054a91;
  }

  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
    color: #fff;
    /* background-color: #054a91; */
    background-color: #3e7cb1;
    border-color: #3e7cb1;
  }

.passwordDiv {
    min-width: 150px;
    max-width: 350px;
}

@media(min-width:850px){
    .HomePageContentDiv{
        padding-top: 10%;
    }

}
.AdminNewPage, .AdminEditPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AdminNewPage form, .AdminEditPage form{
    width:70%;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AdminEditPage form .btn.btn-danger{
    margin-top: 30px;
}

.titleDiv, .descriptionDiv, .intructionsDiv, .prereqDiv, .prereq_descriptionDiv, .tagSelectDiv{
    width: 100%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
}

.titleDiv input, .descriptionDiv textarea, .intructionsDiv textarea, .prereqDiv textarea, .prereq_descriptionDiv textarea {
    width: 100%
}

.descriptionDiv textarea, .intructionsDiv textarea, .prereqDiv textarea, .prereq_descriptionDiv textarea{
    height: 40vh;
}

.tagSelectDiv button{
    margin-top: 10px;
}

.tagsToAddList{
    padding-top: 30px;
}

.original_tag{
    background-color: rgb(255, 180, 89);
}

.takequizAdminBtnDiv{
    margin-top: 3vh;
}
.NotFoundDiv{
    background-image: url('/images/Background.png');
    background-position: center center; 
    background-size: cover;
    background-attachment: fixed; 
    background-repeat: no-repeat; 
    width:100vw;
    height:100vh;
}

.NotFoundPageContentDiv{
    padding-top: 15%;
    transition: padding .5s ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.btn-link, .btn-link:hover{
    color: #fff;
    text-decoration: none;
}

@media(min-width:850px){
    .NotFoundPageContentDiv{
        padding-top: 10%;
    }

}
.multiQuestionDiv, .dualityQuestionDiv, .dropdownQuestionDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 28%;
    transition: padding .3s ;
} 

@media(min-width:525px){
    .multiQuestionDiv, .dualityQuestionDiv, .dropdownQuestionDiv{
        padding-top: 20%;
    }
}

@media(min-width:850px){
    .multiQuestionDiv, .dualityQuestionDiv, .dropdownQuestionDiv{
        padding-top: 13%;
    }
}



.selectDiv{
    width:60%;
    max-width: 350px;
}

.selectDiv select {
    padding: 7px;
    margin-top: 15px;
    margin-bottom: 15px;
    width:100%;
    color:#054a91
}

.dropdownQuestionDiv button, .multiQuestionDiv button, .dualityQuestionDiv button{
    width:60%;
    max-width: 350px;
    background-color: #3e7cb1;
    color:white;
    outline: none;
    border: none;
}

.btn-primary.focus, .btn-primary:focus {
    box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}


.multiQuestionDiv button, .dualityQuestionDiv button{
    margin-top: 15px;
}


button.btn-secondary.goBackBtn{
    background-color: #adadad;
    margin-top: 50px;
    max-width: 200px;
}
button.btn-secondary.goBackBtn:hover{
    background-color: #6d6d6d;
}

.btn-secondary.focus, .btn-secondary:focus {
    box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus{
    box-shadow: none;
}

.multiSelectQuestionDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 5%;
    transition: padding .3s ;
}

.multiSelectQuestionDiv .multiSelectButtons{
    padding: 10px;
}

.multiSelectQuestionDiv .multiSelectButton{
    margin: 7px;
    border-radius: .25rem;
    outline: none;
}

.multiSelectQuestionDiv .selected-multiselect{
    border: rgb(163, 163, 163) solid 2px;
    color: white;
    background-color: #3e7cb1;
}

.multiSelectQuestionDiv .unselected-multiselect{
    border: rgb(124, 124, 124) solid 2px;
}
/* 
from
https://codepen.io/davidicus/pen/yyxoXW


/*== start of code for tooltips ==*/
.tool {
    cursor: help;
    position: relative;
}


/*== common styles for both parts of tool tip ==*/
.tool::before,
.tool::after {
    left: 50%;
    opacity: 0;
    position: absolute;
    z-index: -100;
}

.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 100; 
}


/*== pointer tip ==*/
/* .tool::before {
    border-style: solid;
    border-width: 1em 0.75em 0 0.75em;
    border-color: #3E474F transparent transparent transparent;
    bottom: 100%;
    content: "";
    margin-left: -0.5em;
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
    transform:  scale(.6) translateY(-90%);
} 

.tool:hover::before,
.tool:focus::before {
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
} */


/*== speech bubble ==*/
.tool::after {
    background: #3E474F;
    border-radius: .25em;
    bottom: 178%;
    color: #EDEFF0;
    content: attr(data-tip);
    line-height: 1.2;
    margin-left: -15vw;
    margin-bottom: -50vh;
    padding: 1em;
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
    transform:  scale(1) translateY(-80%);  
    /* width: 17.5em; */
    width:30vw;
}

.tool:hover::after,
.tool:focus::after  {
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
}

@media (max-width: 960px) {
    .tool::after { 
          font-size: 1em;
          /* margin-left: -5em; */
          /* width: 10em;  */
          margin-left: -20vw;
          margin-bottom: -48vh;
          width:40vw;
          
    }
  }

@media (max-width: 760px) {
    .tool::after { 
          font-size: 1em;
          /* margin-left: -5em; */
          /* width: 10em;  */
          margin-left: -25vw;
          margin-bottom: -50vh;
          width:50vw;
          
    }
  }

  @media (max-width: 680px) {
    .tool::after { 
          font-size: 1em;
          /* margin-left: -5em; */
          /* width: 10em;  */
          margin-left: -25vw;
          margin-bottom: -53vh;
          width:50vw;
          
    }
  }

  @media (max-width: 500px) {
    .tool::after { 
          font-size: 1em;
          /* margin-left: -5em; */
          /* width: 10em;  */
          margin-left: -30vw;
          margin-bottom: -55vh;
          width:60vw;
          
    }
  }

  @media (max-width: 400px) {
    .tool::after { 
          font-size: 1em;
          /* margin-left: -5em; */
          /* width: 10em;  */
          margin-left: -30vw;
          margin-bottom: -70vh;
          width:60vw;
          
    }
  }

.tooltiplabel{
    font-weight: bold;
    color: #3e7cb1;
}
select:focus, select:active {
    /* outline: -webkit-focus-ring-color auto 1px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 1px; */
    /* outline-width: 4px; */
    outline: #3e7cb1 1px solid;
    border: none;
}

/* select:not(:-internal-list-box) {
    background-color: orange;
    overflow: visible !important;
} */

/* .EndResultsBackgroundDiv{
    background-image: url('/images/TopBlueBar.png');
    background-position: 0px 0px; 
    background-size: cover;
    background-attachment: fixed; 
    background-repeat: no-repeat; 
    width:100vw;
    height:100vh;
} */

.CenterAll{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.EndResultsTopBarDiv{
    min-height: 10vh;
    background-color: #054a91;
}

.EndResultsContentDiv{
    padding-top: 8px;
}

.btn-link, .btn-link:hover{
    color: #fff;
    text-decoration: none;
}
.preResultMultiSelectDiv .selected-multiselect{
    border: red solid 2px;
}

.preResultMultiSelectDiv .unselected-multiselect{
    border: yellow solid 2px;
}

.preResultMultiSelectDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 5%;
    transition: padding .3s ;
}

.preResultMultiSelectDiv .preResultsSelectButtons{
    padding: 10px;
}

.preResultMultiSelectDiv .preResultsMultiSelect{
    margin: 7px;
    border-radius: .25rem;
    outline: none;
}

.preResultsSelectButtons .selected-multiselect{
    border: rgb(163, 163, 163) solid 2px;
    color: white;
    background-color: #3e7cb1;
}

.preResultsSelectButtons .unselected-multiselect{
    border: rgb(124, 124, 124) solid 2px;
}
