.NotFoundDiv{
    background-image: url('/images/Background.png');
    background-position: center center; 
    background-size: cover;
    background-attachment: fixed; 
    background-repeat: no-repeat; 
    width:100vw;
    height:100vh;
}

.NotFoundPageContentDiv{
    padding-top: 15%;
    transition: padding .5s ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.btn-link, .btn-link:hover{
    color: #fff;
    text-decoration: none;
}

@media(min-width:850px){
    .NotFoundPageContentDiv{
        padding-top: 10%;
    }

}